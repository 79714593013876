#app {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  body * {
    box-sizing: border-box;
  }
  
  .slideContent {
    width: 250px;
    height: 300px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    transition: transform 0.5s ease-in-out;
    opacity: 0.7;
    display: grid;
    align-content: center;
    transform-style: preserve-3d;
    transform: perspective(1000px) translateX(calc(100% * var(--offset))) rotateY(calc(-45deg * var(--dir)));
    border-radius: 15px;
  }
  
  .slideContentInner {
    transform-style: preserve-3d;
    transform: translateZ(2rem);
    transition: opacity 0.3s linear;
    color: white;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
    opacity: 0;
  }
  .slideContentInner .slideSubtitle,
  .slideContentInner .slideTitle {
    font-size: 2rem;
    font-weight: normal;
    letter-spacing: 0.2ch;
    text-transform: uppercase;
    margin: 0;
  }
  .slideContentInner .slideSubtitle::before {
    content: "— ";
  }
  .slideContentInner .slideDescription {
    margin: 0;
    font-size: 0.8rem;
    letter-spacing: 0.2ch;
  }
  
  .slideBackground {
    position: fixed !important;
    top: 0;
    left: -10%;
    right: -10%;
    bottom: 0;
    background-size: cover;
    background-position: center center;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.3s linear, transform 0.3s ease-in-out;
    transform: translateX(calc(10% * var(--dir)));
  }
  
  .slide[data-active] {
    z-index: 2;
    pointer-events: auto;
  }
  .slide[data-active] .slideBackground {
    opacity: 0.1;
    transform: none;
  }
  .slide[data-active] .slideContentInner {
    opacity: 1;
  }
  .slide[data-active] .slideContent {
    --x: calc(var(--px) - 0.5);
    --y: calc(var(--py) - 0.5);
    opacity: 1;
    transform: perspective(1000px);
  }
  .slide[data-active] .slideContent:hover {
    transition: none;
    transform: perspective(1000px) rotateY(calc(var(--x) * 45deg)) rotateX(calc(var(--y) * -45deg));
  }
  
  .slidesWrapper {
    min-height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 100%;
  }
  @media (min-width: 768px) {
    .slidesWrapper {
      width: 750px;
    }
  }
  @media (min-width: 992px) {
    .slidesWrapper {
      width: 970px;
    }
  }
  @media (min-width: 1200px) {
    .slidesWrapper {
      width: 1170px;
    }
  }
  .slidesWrapper *,
  .slidesWrapper *::before,
  .slidesWrapper *::after {
    position: relative;
  }
  
  .slides {
    display: grid;
  }
  .slides > .slide {
    grid-area: 1/-1;
  }
  .slides > .prevSlideBtn,
  .slides .nextSlideBtn {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    background: transparent;
    border: none;
    color: white;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
    position: absolute;
    font-size: 2rem;
    width: 5rem;
    height: 5rem;
    top: 30%;
    transition: opacity 0.3s;
    opacity: 0.7;
    z-index: 5;
  }
  .slides > .prevSlideBtn:hover,
  .slides .nextSlideBtn:hover {
    opacity: 1;
  }
  .slides > .prevSlideBtn:focus,
  .slides .nextSlideBtn:focus {
    outline: none;
  }
  .slides > .prevSlideBtn {
    left: -25%;
  }
  .slides > .nextSlideBtn {
    right: -25%;
  }