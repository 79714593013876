.work {
  display: none;
}

/* Media Queries - Start */

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .text1 {
    font-size: 1rem;
  }

  .mbox {
    padding-top: 25vw;
  }

  .navi {
    margin-right: 0rem;
  }

  .text2 {
    font-size: 0.8rem;
  }
}

@media only screen and (min-width: 480px) and (max-width: 768px) {
  .align {
    height: 6vh;
    width: 40vw;
    justify-content: center;
    margin-top: 6em;
    margin-left: 2.5em;
  }

  .mbox {
    padding-top: 25vw;
  }

  .text1 {
    font-size: 2rem;
  }

  .text2 {
    font-size: 1rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {

  .text1 {
    font-size: 2rem;
  }

  .text2 {
    font-size: 1rem;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1260px) {
  .vid {
    width: 100%;
  }
}

@media only screen and (min-width: 1260px) and (max-width: 1536px) {

  .text1 {
    font-size: 2.5rem;
  }

  .text2 {
    font-size: 1rem;
  }
}

/* Media Queries - End */

.notdisplayed {
  display: none;
}

.displayed {
  display: flex;
  height: 20px;
  margin: auto;
}

.button-style1 {
  position: relative;
  color: inherit;
  font-size: 12px;
  text-transform: uppercase;
  padding: 20px 35px;
  border: none;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  letter-spacing: 0.05em;
  font-weight: 600;
  background: transparent;
  line-height: 1.3;
}

.button-style1 .d:before,
.button-style1 .d:after {
  content: "";
  position: absolute;
  height: 1px;
  -webkit-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

.button-style1,
.button-style2,
.button-style3,
.add-to-cart-button,
.button-style4,
.searchform button,
.protected-post-form .form button {
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: inherit;
  line-height: 1;
  outline: none;
  overflow: hidden;
  padding: 0;
  pointer-events: all;
  position: relative;
  text-decoration: none;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.button-style1 span,
.button-style2 span,
.button-style3 span,
.add-to-cart-button span,
.button-style4 span,
.searchform button span,
.protected-post-form .form button span {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

input.style1,
textarea.style1 {
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background: none;
  color: inherit;
  width: 100%;
}

.button-style1 .d:before {
  top: 0;
  left: 45%;
  right: 10%;
}

.button-style1 .d:after {
  left: 10%;
  right: 65%;
  bottom: 0;
}

.button-style1 .d span:before,
.button-style1 .d span:after {
  content: "";
  position: absolute;
  width: 1px;
  -webkit-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
}

.button-style1 .d span:before {
  top: 0;
  left: 0;
  bottom: 70%;
}

.button-style1 .d span:after {
  top: 50%;
  right: 0;
  bottom: 10%;
}

.button-style1:hover .d:before,
.button-style1:hover .d:after {
  left: 0;
  right: 0;
}

.button-style1:hover .d span:before,
.button-style1:hover .d span:after {
  top: 0;
  bottom: 0;
}

.button-style1,
.button-style2,
.button-style3,
.add-to-cart-button,
.button-style4,
.searchform button,
.protected-post-form .form button {
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: inherit;
  line-height: 1;
  outline: none;
  overflow: hidden;
  padding: 0;
  pointer-events: all;
  position: relative;
  text-decoration: none;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.button-style1 span,
.button-style2 span,
.button-style3 span,
.add-to-cart-button span,
.button-style4 span,
.searchform button span,
.protected-post-form .form button span {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

@media only screen and (min-width: 1536px) and (max-width: 2160px) {
  .vid {
    height: 100vh;
    width: 100%;
  }

  .text1 {
    font-size: 3rem;
  }

  .text2 {
    font-size: 1rem;
  }
}

.main:hover .work {
  display: inline;
}

.button-style1 {
  position: relative;
  color: inherit;
  font-size: 12px;
  text-transform: uppercase;
  padding: 20px 35px;
  border: none;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  letter-spacing: 0.05em;
  font-weight: 600;
  background: transparent;
  line-height: 1.3;
}

.button-style1 .d:before,
.button-style1 .d:after {
  content: "";
  position: absolute;
  height: 1px;
  -webkit-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

.button-style1 .d:before {
  top: 0;
  left: 45%;
  right: 10%;
}

.button-style1 .d:after {
  left: 10%;
  right: 65%;
  bottom: 0;
}

.button-style1 .d span:before,
.button-style1 .d span:after {
  content: "";
  position: absolute;
  width: 1px;
  -webkit-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
}

.button-style1 .d span:before {
  top: 0;
  left: 0;
  bottom: 70%;
}

.button-style1 .d span:after {
  top: 50%;
  right: 0;
  bottom: 10%;
}

.button-style1:hover .d:before,
.button-style1:hover .d:after {
  left: 0;
  right: 0;
}

.button-style1:hover .d span:before,
.button-style1:hover .d span:after {
  top: 0;
  bottom: 0;
}

.button-style1 i {
  font-size: 10px;
  margin-left: 15px;
}

.button-style1 .d span:before,
.button-style1 .d span:after {
  content: "";
  position: absolute;
  width: 1px;
  transition: all 500ms ease-in-out;
  background-color: #f65868;
}

.button-style1 .d:before {
  top: 0;
  left: 45%;
  right: 10%;
  background: #f65868;
}

.button-style1 .d:after {
  left: 10%;
  right: 65%;
  bottom: 0;
  background: #f65868;

  
}
@keyframes glitch-2 {
  1% {
    transform: rotateX(10deg) skewX(70deg);
  }

  2% {
    transform: rotateX(0deg) skewX(0deg);
  }
}

svg {
  font-family: "Russo One", sans-serif;
  position: relative;
  width: 100%;
  height: 100%;
}

svg text {
  text-transform: uppercase;
  animation: stroke 5s infinite alternate;
  stroke-width: 2;
  stroke: #365fa0;
  font-size: 9rem;
}

@keyframes stroke {
  0% {
    fill: rgba(72, 138, 20, 0);
    stroke: #f65868;
    stroke-dashoffset: 25%;
    stroke-dasharray: 0 50%;
    stroke-width: 2;
  }

  70% {
    fill: rgba(72, 138, 20, 0);
    stroke: #f65868;
  }

  80% {
    fill: rgba(72, 138, 20, 0);
    stroke: #f65868;
    stroke-width: 3;
  }

  100% {
    fill: #f65868;
    stroke: rgba(54, 95, 160, 0);
    stroke-dashoffset: -25%;
    stroke-dasharray: 50% 0;
    stroke-width: 0;
  }
}
